<template>
  <div class="col-12 mb-4">
    <div class="row align-items-center">
      <div class="col-md-6 col-lg-5 col-xl-4 border_end">
        <div class="row justify-content-center justify-content-md-start">
          <div class="col-12 bold font17 normal-text-dark pe-0">
            {{ stepNumber}}. Enter Quantity
          </div>
          <div class="col-10 col-md-12 col-lg-10 col-xl-9 col-xxl-8 mt-3">
            <NumberField color="gold" v-model="quantityValue" id="quantity" />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg mt-4 mt-md-0">
        <div class="row">
          <div class="col-12">
            <div class="row m-0">
              <div class="col-12">
                <div class="row m-0">
                  <div class="col-12 bold green-text-dark font15 px-1">
                    Donation Total
                  </div>
                  <div class="col-12 total px-1">
                    {{displayTotalAndCurrency}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    NumberField: defineAsyncComponent(() => import('@/components/NumberField.vue'))
  },
  props: ['quantity', 'displayTotalAndCurrency', 'stepNumber'],
  emits: ['update:quantity', 'updateQuantity'],
  name: 'Project Donate Enter Quantity',
  computed: {
    quantityValue: {
      get () {
        return this.quantity
      },
      set (val) {
        this.$emit('update:quantity', val)
        setTimeout(() => {
          this.$emit('updateQuantity')
        }, 20)
      }
    }
  }
}
</script>

<style scoped>
.total {
  font-family: "quicksand", Sans-serif;
  font-size: 45px;
  font-weight: 400;
  color: var(--green-color);
  line-height: 1;
}

.border_end {
  border-right: none;
}
@media (min-width: 992px) {
  .border_end {
    border-right: 1px solid rgb(204, 204, 204);
  }
}
</style>
